import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, } from 'react-quick-style-components';
import { Input, Button } from 'components'
import Store from 'store';
import { Ionicons } from "@expo/vector-icons";
import moment from 'moment-timezone';
import { TimeHelper } from 'helpers';

const MyPlanOther = () => {
  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const [personalObjectives, setPersonalObjectives] = useState<{ id?: number, content: string }>({ content: '' });
  const personalObjectivesRef = useRef<{ id?: number, content: string }>({ content: '' });
  useEffect(() => {
    personalObjectivesRef.current = personalObjectives;
  }, [personalObjectives]);
  const [keyQuestions, setKeyQuestions] = useState<Array<{ id: number, question: string, replies: { list: Array<any> } }>>([]);
  const rV = useDynamicResponsiveValue();
  const [saveStatus, setSaveStatus] = useState<'' | 'SAVING' | 'SAVED'>('');
  const [{ }, cApi] = Store.Client.createStore();
  const [tempQuestion, setTempQuestion] = useState('');
  const [temReply, setTempReply] = useState<{ [questionId: number]: string }>({});

  const editorInitRef = useRef(false);

  useEffect(() => {
    if (editorInitRef.current) return;
    if (!personalObjectives.id) return;
    setTimeout(() => {
      const markdownEditor = document.querySelector('#joint-objectives-editor');
      if (!markdownEditor) return;
      const Editor = window.toastui.Editor;
      const editor = new Editor({
        el: markdownEditor,
        height: 'auto',
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        hideModeSwitch: true,
        initialValue: personalObjectives.content,
        toolbarItems: [
          ['bold', 'italic'],
          ['link'],
        ],
        events: {
          change: () => {
            // setPersonalObjectives({
            //   id: personalObjectivesRef.current.id,
            //   content: editor.getMarkdown(),
            // });
            handlePersonalObjectivesChange(editor.getMarkdown());
            setUpLinkHover()
          }
        }
      });
      setUpLinkHover();
      editorInitRef.current = true;
    }, 500);
  }, [personalObjectives]);

  const setUpLinkHover = () => {
    const links = document.querySelectorAll('#joint-objectives-editor a');
    links.forEach((link) => {
      link.onmouseenter = (event) => {
        if (event.target.tooltipRef) return;
        const tooltip = document.createElement("span");
        tooltip.classList.add("editor-tooltip");
        tooltip.innerHTML = `<a href="${event.target.href}" target="_blank" style="color: white; text-decoration: none;">Open in new tab <span class="open-icon">↗</span></a>`;
        document.body.appendChild(tooltip);

        const rect = event.target.getBoundingClientRect();
        console.log('rect', rect);
        tooltip.style.position = "absolute";
        tooltip.style.top = `${event.pageY + 10}px`;
        tooltip.style.left = `${event.pageX + 10}px`;

        event.target.tooltipRef = tooltip;

        tooltip.onmouseenter = () => {
          clearTimeout(event.target.tooltipTimeout);
        };
        tooltip.onmouseleave = () => {
          tooltip.remove();
        };
      };
      link.onmouseleave = (event) => {
        event.target.tooltipTimeout = setTimeout(() => {
          if (event.target.tooltipRef) {
            event.target.tooltipRef.remove();
            event.target.tooltipRef = null;
          }
        }, 200);
      };
    });
  }

  useEffect(() => {
    setPersonalObjectives({
      ...personalObjectives,
      content: 'Loading...',
    });
    cApi.getPersonalObjectives().then(([data, err]: any) => {
      setPersonalObjectives({
        id: data?.id,
        content: data?.content || ''
      });
    })
      .catch((err: any) => {
        setPersonalObjectives({
          ...personalObjectives,
          content: '',
        })
      });
  }, []);

  useEffect(() => {
    cApi.getKeyQuestions().then(([data, err]: any) => {
      setKeyQuestions(data);
    })
  }, []);

  const send = async () => {
    cApi.upsertKeyQuestions({
      question: tempQuestion,
      replies: { list: [] },
    }).then(([data, err]: any) => {
      if (!err) {
        setKeyQuestions([...keyQuestions, data]);
        setTempQuestion('');
      }
    });
  };

  const sendReply = (questionId: number, content: string) => {
    const newQuestions = keyQuestions.slice();
    const questionIndex = newQuestions.findIndex((val) => val.id === questionId);
    if (questionIndex === -1) {
      alert('Question not found');
      return;
    }
    const question = keyQuestions[questionIndex];
    question.replies.list.push({
      content,
      from: firebaseUser.displayName,
      timestamp: new Date().getTime(),
    });
    newQuestions[questionIndex] = question;
    setKeyQuestions(newQuestions);
    cApi.upsertKeyQuestions({
      id: question.id,
      replies: question.replies,
    }).then(([data, err]: any) => {
      if (!err) {
        setTempReply({
          ...temReply,
          [questionId]: ''
        });
      } else {
        // remove the last reply
        question.replies.list.pop();
        newQuestions[questionIndex] = question;
        setKeyQuestions(newQuestions);
      }
    })
  }

  const timeoutRef = useRef(null);
  const handlePersonalObjectivesChange = (text) => {
    setPersonalObjectives({
      id: personalObjectivesRef.current.id,
      content: text,
    });
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setSaveStatus('SAVING');
      cApi.upsertPeronalObjectives({
        id: personalObjectivesRef.current.id,
        content: text,
      }).then(() => {
        setSaveStatus('SAVED');
      })
        .catch((err: any) => {
          setSaveStatus('');
        });
    }, 1000);
  }

  return (
    <Row xs="100%" md="50%" alignItems="flex-start" gap={64}>
      <Col paddingRight={rV({ xs: 10, md: 16 })} paddingLeft={rV({ xs: 10, md: 0 })} paddingTop30>
        <Text fontFamily='PlayfairDisplay_600SemiBold' fontSize30 lineHeight40 marginBottom14>
          Joint Objectives
        </Text>
        {!!personalObjectives.id ? (
          // <Input
          //   styleInput={{
          //     minHeight: rV({ xs: 200, md: 427 }),
          //     padding: 16,
          //     fontSize: 16,
          //     lineHeight: 21,
          //     background: "white",
          //     maxWidth: "100%",
          //     border: "1px solid #000000",
          //   }} inputProps={{ multiline: true }}
          //   placeholder="Enter your objectives..."
          //   marginBottom14 value={personalObjectives.content} onChangeText={handlePersonalObjectivesChange} >
          // </Input>
          <div id="joint-objectives-editor">

          </div>
        ) : <Text>Loading..</Text>}
        <Text caption>{saveStatus}</Text>
      </Col>
      <Col paddingLeft={rV({ xs: 10, md: 16 })} paddingRight={rV({ xs: 10, md: 0 })} paddingTop30>
        <Text fontFamily='PlayfairDisplay_600SemiBold' fontSize30 lineHeight40 marginBottom14>
          Key Questions
        </Text>
        <Col minHeight={rV({ xs: 200, md: 427 })} padding24 marginBottom14 backgroundColor={"#0000000A"}>
          <Text lineHeight21 fontSize16 marginBottom8>Question</Text>
          <Input
            styleInput={{
              height: 62,
              paddingVertical: 20,
              paddingHorizontal: 16,
              fontSize: 16,
              background: "white",
              border: "1px solid #00000033",
            }}
            inputProps={{ placeholderTextColor: "#000" }}
            value={tempQuestion}
            placeholder="Enter your question..."
            onChangeText={(text) => { setTempQuestion(text) }}
          />
          <Button
            titleStyle={{ fontSize: 18 }}
            marginTop23
            title="Submit"
            width150
            onPress={send}
          />
          <Col marginTop20>
            {keyQuestions.map((val, valI) => (
              <Col key={'key-question-' + val.id + valI}>
                <Text lineHeight21 fontSize16 marginBottom8 bold>{val.question}</Text>
                <Col marginLeft15>
                  {Boolean(!!val.replies?.list?.map) && val.replies?.list?.map((reply, replyI) => (
                    <Col marginBottom10>
                      <Text marginBottom5 key={'key-question-reply-' + reply.id + replyI} lineHeight18 fontSize14>
                        {reply.content}
                      </Text>
                      <Text color="rgba(0,0,0,0.6)" fontSize10>{reply.from} | {TimeHelper.format(reply.timesamp, 'DD/MM/YYYY HH:mm:ss')}</Text>
                    </Col>
                  ))}
                  <Col marginBottom10>
                    <Input
                      styleInput={{
                        height: 42,
                        paddingVertical: 14,
                        paddingHorizontal: 14,
                        fontSize: 14,
                        background: "white",
                        border: "1px solid #00000033",
                      }}
                      inputProps={{
                        placeholderTextColor: "#000",
                        onSubmitEditing: () => {
                          sendReply(val.id, temReply[val.id]);
                        }
                      }}
                      value={temReply[val.id] || ''}
                      placeholder="Type your reply..."
                      onChangeText={(text) => {
                        setTempReply({
                          ...temReply,
                          [val.id]: text
                        })
                      }}
                    />
                    <Col absolute top0 right0 bottom0 width42 middle
                      onPress={() => {
                        sendReply(val.id, temReply[val.id])
                      }}
                    >
                      <Ionicons name="send" size={24} color="black" />
                    </Col>
                  </Col>
                </Col>
              </Col>
            ))}
          </Col>
        </Col>
      </Col>
    </Row>
  );
}

export default MyPlanOther;