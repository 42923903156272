
import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Text, useDynamicResponsiveValue, Img, Scroll } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, fSize, ICON, SCREEN_NAME, ASSETS } from 'const';
import { LinearGradient } from 'expo-linear-gradient';
import { showModal, hideModal } from '../legacy_modal';
import { headerNavs } from './headerNav';
import Store from 'store';
interface IHeaderSideMenuProps {
  navigation: {
    navigate: Function,
    goBack: Function,
  },
  route: {
    name: string,
  },
  name: 'GroupPortal' | 'Vintage Whisky Club' | 'Vintage Bottlers' | 'Vintage Auction House',
  indexRoute: number,
}

const SOCIALS = [
  { img: ICON.Facebook },
  { img: ICON.Twitter },
  { img: ICON.Instagram },
  { img: ICON.Youtube },
];

const HeaderSideMenuDrawer = ({ navigation, route, name, indexRoute }: IHeaderSideMenuProps) => {
  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const [{ saleManagerData }] = Store.Client.createStore();
  const [isAdmin, setIsAdmin] = useState(false);
  const rV = useDynamicResponsiveValue();

  const [listItems, setListItems] = useState([]);

  useEffect(() => {

    const getData = async () => {
      if (!firebaseUser ||  !firebaseUser.email) return;
      const [res, err] = await uApi.checkAdmin(firebaseUser.email);
      if (!!res) setIsAdmin(true);
    };
    getData();

  }, [firebaseUser]);

  useEffect(() => {

    const dashboard = { icon: ASSETS.HD_DASHBOARD_2, title: 'Account Summary', screen: SCREEN_NAME.Home };
    const yourInvestment = { icon: ASSETS.HD_INVESTMENT_2, title: 'Your Casks', screen: SCREEN_NAME.PortfolioDetails };
    const casksIOwn = { icon: ASSETS.HD_INVESTMENT_2, title: 'My Plan', screen: SCREEN_NAME.MyPlan };
    const invoice = { icon: ASSETS.HD_INVOICES_2, title: 'Invoices', screen: SCREEN_NAME.Invoices };
    const events = { icon: ASSETS.HD_EVENT, title: 'Events', screen: SCREEN_NAME.Events };
    const news = { icon: ASSETS.HD_NEWS_2, title: 'News', screen: SCREEN_NAME.News };
    const refer = { icon: ASSETS.HD_REVIEW_2, title: 'Refer a friend', screen: SCREEN_NAME.ReferAFriend };
    const contactus = { icon: ASSETS.HD_REVIEW_2, title: 'Contact Us', screen: SCREEN_NAME.ContactUs };
    const autions = { icon: ASSETS.HD_AUNCTION, title: 'Auctions', screen: SCREEN_NAME.Auctions };
    const becomeSeller = { icon: ASSETS.HD_SELLER, title: 'Become a seller', screen: SCREEN_NAME.BecomeASeller };
    const paymenmethod = { icon: ASSETS.HD_PAYMENT, title: 'Payment methods', screen: SCREEN_NAME.PaymentMethod };
    const order = { icon: ASSETS.HD_ORDER, title: 'Orders', screen: SCREEN_NAME.Orders };
    const address = { icon: ASSETS.HD_ADDRESS, title: 'Addresses', screen: SCREEN_NAME.Addresses };
    const logout = { icon: ASSETS.HD_LOGOUT_2, title: 'Logout', screen: SCREEN_NAME.Logout };
    const importData = { icon: ASSETS.HD_ACCOUNT_2, title: 'Import Data', screen: SCREEN_NAME.ImportData };
    const heatMap = { icon: ASSETS.HD_ACCOUNT_2, title: 'Heat Map [Admin]', screen: SCREEN_NAME.AdminHeatMap };
    const uploadAssets = { icon: ASSETS.HD_ACCOUNT_2, title: 'Upload Assets [Admin]', screen: SCREEN_NAME.AdminUploadAssets };
    const syncData = { icon: ASSETS.HD_ACCOUNT_2, title: 'Sync Data [Admin]', screen: SCREEN_NAME.AdminSyncData };
    const manageUsers = { icon: ASSETS.HD_ACCOUNT_2, title: 'Manage Users [Admin]', screen: SCREEN_NAME.AdminManageUsers };
    const manageClient = { icon: ASSETS.HD_ACCOUNT_2, title: 'Manage Clients', screen: SCREEN_NAME.ManageClients };

    // console.log('name', name);

    const listNavs = name === 'GroupPortal' ?
      [dashboard, yourInvestment, casksIOwn, news, refer, contactus, logout] :
      name === 'Vintage Whisky Club' ?
        [dashboard, events, news, logout] :
        name === 'Vintage Bottlers' ?
          [dashboard, address, logout] :
          name === 'Vintage Auction House' ?
            [dashboard, address, becomeSeller, logout] : [dashboard, yourInvestment, casksIOwn, news, refer, contactus, logout]

    if (isAdmin) {
      listNavs.unshift(heatMap);
      listNavs.unshift(uploadAssets);
      listNavs.unshift(syncData);
      listNavs.unshift(manageUsers);
    }
    if (saleManagerData.clients.length > 0) {
      listNavs.unshift(manageClient);
    }
    setListItems(listNavs);
  }, [name, isAdmin])

  const withClose = (callback = () => { }) => {
    hideModal();
    callback();
  };

  const renderItem = (val, i) => {
    return (
      <Row
        padding={24}
        key={'noti-dropdown-' + i}
        onPress={() => {
          hideModal();
          if (!!val.path) window.location.pathname = val.path;
          else !!val.screen && navigation.navigate(val.screen);
        }}
        onHoverStyle={{ backgroundColor: 'rgba(0,0,0,0.05)' }}
      >
        <Col width={fSize(rV, 40)} height={fSize(rV, 40)} marginRight14>
          <Img source={val.icon} width100p height100p resizeMode="contain" />
        </Col>
        <Text fontSize={fSize(rV, 20)}>{val.title}</Text>
      </Row>
    );
  }

  return (
    <Row stretch height={DEVICE_INFO.HEIGHT} width={rV({ xs: DEVICE_INFO.WIDTH, md: 500 })}>
      <Col flex1 bgWhite>

        <Row justifyContent="space-between">
          {!indexRoute ? <Col /> : (
            <Row paddingHorizontal5 marginLeft24 onPress={() => withClose(() => navigation.goBack())}>
              <Text fontSize25>←</Text>
              <Text fontSize={16}> Go back</Text>
            </Row>
          )}
          <Col padding={fSize(rV, 41)} onPress={() => withClose()}><ICON.Close width={fSize(rV, 41)} height={fSize(rV, 41)} /></Col>
        </Row>
        <Scroll flex1>
          {listItems.map(renderItem)}
          <Col padding24>
            <Row justifyContent={rV({ xs: "space-around", lg: 'flex-start' })}>
              {SOCIALS.map((social, i) => (
                <Col key={`img${i}`}
                  marginHorizontal={rV({ xs: 10, lg: 0 })}
                  height={fSize(rV, 40)} width={fSize(rV, 60)} resizeMode="contain"
                >
                  <social.img fill="black" width={30} height={30} />
                </Col>
              ))}
            </Row>
          </Col>
        </Scroll>
      </Col>
    </Row>
  );
};

export const useHeaderSideMenuDrawer = (navigation, route, { name }, indexRoute) => {

  const showDrawer = () => {
    showModal({
      component: (
        <HeaderSideMenuDrawer navigation={navigation} route={route} name={name} indexRoute={indexRoute} />
      ),
      modalProps: {
        style: {
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
      noWrapper: true,
      animation: 'slideInRight',
    })
  };

  return {
    showDrawer,
    hideDrawer: hideModal,
  };
};
