export const SCREEN_NAME = {
  Home: 'Home',
  HelloWorld: 'HelloWorld',
  CloneMe: 'CloneMe',
  CloneMe2: 'CloneMe2',
  Casks: 'Casks',
  Login: 'Login',
  Orders: 'Orders',
  Events: 'Events',
  NewDetail: 'NewDetail',
  Register: 'Register',
  PortfolioDetails: 'PortfolioDetails',
  PaymentMethod: 'PaymentMethod',
  Dashboard: 'Dashboard',
  Auctions: 'Auctions',
  Addresses: 'Addresses',
  ReferAFriend: 'ReferAFriend',
  AccountDetail: 'AccountDetail',
  News: 'News',
  Invoices: 'Invoices',
  Logout: 'Logout',
  Portfolio: 'Portfolio',
  AccountSummary: 'AccountSummary',
  DashboardVintageBottlers: 'DashboardVintageBottlers',
  BecomeASeller: 'BecomeASeller',
  ImportData: 'ImportData',
  AdminHeatMap: 'AdminHeatMap',
  CasksIOwn: 'CasksIOwn',
  LoginMaster: 'LoginMaster',
  ContactUs: 'ContactUs',
  AdminUploadAssets: 'AdminUploadAssets',
  AdminSyncData: 'AdminSyncData',
  ForgottenPassword: 'ForgottenPassword',
  ChangePassword: 'ChangePassword',
  ManageClients: 'ManageClients',
  MyPlan: 'MyPlan',
  Collections: 'Collections',
  RegisterGSB: 'RegisterGSB',
  LoginGSB: 'LoginGSB',
  YourCasks: 'YourCasks',
  Certificates: 'Certificates',
  AdminManageUsers: 'AdminManageUsers',
};