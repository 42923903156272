import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc, Reset } from 'navigation';
import { IScreen, ILocalFileUpload } from 'type';
import { HeatMap, Table, TableWithAction, Tabs, } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Button, Input, ChatBox, Uploader, Dropdown, UploaderMultiple } from 'components';
import { VarHelper } from 'helpers';
import { TSingleOption } from 'components/Dropdown';
import Store from 'store'

const SPACE_UNIT = 50;

const AdminManageUsers: IScreen = () => {
  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const [{}, sApi] = Store.System.createStore();

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [userLogin, setUserLogin] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const ScreenContainer = React.Fragment;

  if (!firebaseUser || !firebaseUser.email) {
    return (
      <Reset to={SCREEN_NAME.Home} />
    )
  }

  const syncMasterData = async () => {
    if (loading1) return;
    setLoading1(true);
    const [res, err] = await sApi.syncMasterData();
    setLoading1(false);
    if (err) return alert(err);
    if (res.error) return alert(res.error);
    alert('Master Data synced successfully');
  }

  const resetPassword = async () => {
    if (loading1) return;
    setLoading1(true);
    const [res, err] = await uApi.adminResetUserPassword(undefined, undefined, resetEmail);
    setLoading1(false);
    if (err) return alert(err);
    if (res?.error) return alert(res.error);
    setNewPassword(res.data.newPassword);
  }

  const inspectUser = async () => {
    if (loading2) return;
    setLoading2(true);
    const [res, err] = await uApi.adminLoginUser(undefined, userLogin);
    setLoading2(false);
    if (err) {
      console.log(err);
      alert('Can not login do a client view for this user. The user might not exist in the system');
      return;
    }
    if (res?.error) return alert(res.error);
  }
  
  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col height100p middle>
          <Col width300>
            <Text bold fontSize={24} colorMain>Reset Password</Text>
            <Input label="Email Address"
              width300
              value={resetEmail} onChangeText={(text) => { setResetEmail(text); setNewPassword(''); }} alignSelf="center"
            />
            <Button
              title={loading1 ? "Resetting..." : "Reset Password"}
              preset='main' paddingHorizontal15 marginTop20
              onPress={resetPassword}
            />
            {newPassword && (
              <Col>
                <Text fontSize={24} colorMain>New Password</Text>
                <Text bold>{newPassword}</Text>
              </Col>
            )}
          </Col>

          <Col marginVertical={40} height={2} width300 backgroundColor={'rgba(0,0,0,0.1)'} />

          <Col width300 marginTop={20}>
            <Text bold fontSize={24} colorMain>User View</Text>
            <Text>Useful when you do not want to reset user password</Text>
            <Input label="Email Address"
              width300
              value={userLogin} onChangeText={(text) => setUserLogin(text)} alignSelf="center"
            />
            <Button
              title={loading2 ? "Inspecting..." : "User View"}
              preset='main' paddingHorizontal15 marginTop20
              onPress={inspectUser}
            />
          </Col>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

AdminManageUsers.routeInfo = {
  title: 'Manage Users',
  path: '/admin/manage-users',
};

export default AdminManageUsers;